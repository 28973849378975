import React from 'react'
import Navbar from './Navbar'
import DB_brandmark_logo1 from "../Images/DB_brandmark_logo1.svg"
import mapImg from "../Images/mobileMap.svg"
import Nav from './Nav'
import { IMG_CDN_URL } from '../constant/constantData'
import Footer from './Footer'

const Contact = () => {
    return (
        <>
            <section className='contact-page' >
                <div className='header'>
                    <div>
                        <h1>DIGIBUDDING</h1>
                        <img className='logo' src={`${IMG_CDN_URL}DB_brandmark_logo1.svg`}></img>
                    </div>
                    <Navbar />

                </div>

                <div className='small-device-nav'>
                    <Nav />


                  

                    {/* <div className='small-device-content'>
                        <img src={DB_brandmark_logo1}></img>
                        <h1 className='logo-heading'>DigiBudding</h1>

                        <p>Welcome to Digibudding, the ultimate e-commerce app connecting buyers and sellers in a whole new way. Our unique feature helps you find regional sellers and supports  businesses.</p>
                        <p>  Discover a diverse range of products, from fashion to electronics, and services like education and real estate, all in one place📍</p>

                    </div> */}
                </div>

                <div className='contact-content'>
                    <img src={DB_brandmark_logo1}></img>
                    <h1>CONTACT</h1>

                </div>

            </section>
            <div className='contact-main-section'>
                <h1>Get In Touch With Us</h1>
                <p>For More Information About Our Product & Services. Please Feel Free To Drop <br /> Us An Email. We Are There To Help You Out. Do Not Hesitate!</p>

                <div className='contact-form-section'>
                    <div className='address'>
                        <h2 className='adress-heading'>Adress</h2>
                        <p>Gaur City Center, Greater Noida W Rd
                            Uttar Pradesh 201009
                        </p>

                        <h2 className='adress-heading'>Mobile</h2>
                        <p>+91 88604 68116
                        </p>

                        <h2 className='adress-heading'>Email</h2>
                        <p>connect@digibudding.com
                        </p>

                        <img className='mapImg' src={mapImg}/>

                    </div>

                    <div className='form'>
                        <label>Your Name</label><br/>
                        <input></input>

                        <label>Email address</label><br/>
                        <input></input>

                        <label>Subject</label><br/>
                        <input></input>

                        <label>Message</label><br/>
                        <textarea></textarea>

                        <button className="form-btn">Submit</button>

                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Contact