// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :root {
    --primary: #3acbf7;
} */

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.btn:hover {
  padding: 8px 20px;
  color: #6568f4;
  transition: 250ms;
}

/* .btn-primary {
  background-color: var(--primary);
} */

/* .btn-outline {
  background-color: transparent;
  color: #fff;
  border: 1px solid var(--primary);
} */

.btn-medium {
  font-size: 18px;
  color: #fff;
}

.btn-large {
  padding: 12px 20px;
  font-size: 20px;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,iBAAiB;AACnB;;AAEA;;GAEG;;AAEH;;;;GAIG;;AAEH;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb","sourcesContent":["/* :root {\r\n    --primary: #3acbf7;\r\n} */\r\n\r\n.btn {\r\n  padding: 8px 20px;\r\n  border-radius: 4px;\r\n  outline: none;\r\n  border: none;\r\n  cursor: pointer;\r\n  transition: all 0.3s ease-out;\r\n}\r\n\r\n.btn:hover {\r\n  padding: 8px 20px;\r\n  color: #6568f4;\r\n  transition: 250ms;\r\n}\r\n\r\n/* .btn-primary {\r\n  background-color: var(--primary);\r\n} */\r\n\r\n/* .btn-outline {\r\n  background-color: transparent;\r\n  color: #fff;\r\n  border: 1px solid var(--primary);\r\n} */\r\n\r\n.btn-medium {\r\n  font-size: 18px;\r\n  color: #fff;\r\n}\r\n\r\n.btn-large {\r\n  padding: 12px 20px;\r\n  font-size: 20px;\r\n  color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
