import React from 'react'
// import MapImg from "../Images/Map.svg"
// import GirlImg from "../Images/Girl.svg"
// import InvertedImg from "../Images/Inverted.svg"
// import SpringImg from "../Images/Spring.svg"
// import Star from "../Images/Star 4.svg"
import { IMG_CDN_URL } from '../constant/constantData'
import Banner from './Banner'

const Shop = () => {
    return (
        <>
            <section>
               
                <div className='store-content'>
                    <img src={`${IMG_CDN_URL}Map.svg`} />
                    <div className='store-sub-content'>
                        <h1>What’s In store for <br /> Shoppers?</h1>
                        <p>Discovering Regional sellers who offer the <br /> products and services you need in your area has <br /> never been easier!</p>

                        <p> With our app, you can not only find local <br /> businesses but also unlock exclusive offers and <br /> deals!</p>
                    </div>
                </div>

                <div className='store-main-content' style={{
                    backgroundImage: `url(${`${IMG_CDN_URL}Girl.svg`})`, width: "100%", height: "900px", backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat', backgroundPosition: "bottom"
                }}>
                    {/* <img src={GirlImg} /> */}
                    <div className='card'>
                        <img className='starImg' src={`${IMG_CDN_URL}Star 4.svg`} />
                        <img className='SpringImg' src={`${IMG_CDN_URL}Spring.svg`} />
                        <div>
                            <img className='inverted-img' src={`${IMG_CDN_URL}Inverted.svg`} />
                        </div><br />
                        <p>Did you know there's a hidden seller in every one of our customers? <br />
                            At DigiBudding, we believe in the entrepreneurial spirit within you. With our <br /> platform, you can also transform into a business owner. Explore your <br /> potential and join the world of entrepreneurship!</p>

                    </div>
                </div>


            </section>

        </>
    )
}

export default Shop