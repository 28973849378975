import React from 'react'
import Navbar from './Navbar'
import DB_brandmark_logo1 from "../Images/DB_brandmark_logo1.svg"
import hornImg from "../Images/horn.svg"
import leftCard from "../Images/LeftContent.svg"
import rightCard from "../Images/RightContent.svg"
import Nav from './Nav'
import Footer from './Footer'

const About = () => {
  return (
    <>
    <div style={{background:"black"}}>
      <section className='about-page-header'>
      <div className='header'>
                    <div>
                        <h1>DIGIBUDDING</h1>
                        <img className='logo' src={DB_brandmark_logo1}></img>
                    </div>
                    <Navbar />

                </div>

                <div className='small-device-nav'>
                    <Nav />
                </div>

                <div className='about-content '>
                <img className='brand-logo' src={DB_brandmark_logo1}></img>
                <h1 className='brand-heading'>DigiBudding</h1>
                <p>Welcome to DigiBudding, the ultimate e-commerce app connecting buyers <br/> and sellers in a whole new way. Our unique feature helps you find regional<br/> sellers and supports  businesses.</p>
                <p>Discover a diverse range of products, from fashion to electronics, and services <br/> like education and real estate, all in one place📍</p>
                 
                 <div className='about-btn'>
                  <input className='input-field' placeholder='Your email'/>
                  <button className='about-page-btn'>send</button>
                 </div>
                </div>
       

      </section>

      <section className="about-page-section">
      <img className='brand-logo' src={DB_brandmark_logo1}></img>
                <h1 className='brand-heading'>Everything You need, All <br/> in One Place.</h1>

                <div className='about-card-flexbox'>
                  <img className='leftCard' alt='cardImg' src={leftCard}/>
                  <img className='rightCard' alt='cardImg' src={rightCard}/>
                  <img className='hornImg' alt="hornImg" src={hornImg}/>

                </div>
      </section>
      </div>
      <Footer/>
    </>
  )
}

export default About