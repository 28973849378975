import React, { useState } from 'react'
import DB_brandmark_logo1 from "../Images/DB_brandmark_logo1.svg"
import Navbar from './Navbar'
import Nav from './Nav'
import Footer from './Footer'
import axios from "axios"

const DeleteAccount = () => {


  const [mobileNumber, setMobileNumber] = useState();

  const onDelete = () => {
    if (mobileNumber && mobileNumber?.toString()?.length === 10) {

      const username = 'admin';
      const password = 'DB@AKAK3615';
      const authHeader = 'Basic ' + btoa(`${username}:${password}`);

      let config = {
        method: 'get',
        mode: "cors",
        url: `http://localhost:4000/owner/delete_account/?mobile_number=${mobileNumber}`,
        headers: {
          // "Content-Type": "application/json",
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Credentials': "true",
          // 'Authorization': authHeader
        },
      };

      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          alert('Your Delete Account Request is Submitted!!');
        })
        .catch((error) => {
          console.log(error);
        });

      // fetch(`http://localhost:4000/platform/delete_account/?mobile_number=${mobileNumber}`, {
      //   method: 'GET',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //     'Authorization': basicAuth,
      //   },
      //   // credentials: 'include',
      // })
      //   .then(response => {
      //     if (!response.ok) {
      //       throw new Error('Network response was not ok');
      //     }
      //     alert('Your Delete Account Request is Submitted!!');
      //     return response.json();
      //   })
      //   .then(data => {
      //     console.log(data);
      //   })
      //   .catch(error => {
      //     console.error('There was a problem with the fetch operation:', error);
      //   });


      // fetch(`https://6587de5f90fa4d3dabf93242.mockapi.io/sample/product`, {
      //   method: 'POST',
      //   headers: {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //     // 'Authorization': basicAuth,
      //   },
      //   // credentials: 'include',
      //   body: {}
      // })
      //   .then(response => {
      //     if (!response.ok) {
      //       throw new Error('Network response was not ok');
      //     }
      //     alert('Your Delete Account Request is Submitted!!');
      //     return response;
      //   })
      //   .then(data => {
      //     console.log(data);
      //   })
      //   .catch(error => {
      //     console.error('There was a problem with the fetch operation:', error);
      //   });


    }
  }

  return (
    <>
      <section className='privacy-policy'>
        <div className='header'>
          <div>
            <h1>DIGIBUDDING</h1>
            <img className='logo' src={DB_brandmark_logo1}></img>
          </div>
          <Navbar />

        </div>

        <div className='small-device-nav'>
          <Nav />




          {/* <div className='small-device-content'>
                        <img src={DB_brandmark_logo1}></img>
                        <h1 className='logo-heading'>DigiBudding</b>

                        <p>Welcome to Digibudding, the ultimate e-commerce app connecting buyers and sellers in a whole new way. Our unique feature helps you find regional sellers and supports  businesses.</p>
                        <p>  Discover a diverse range of products, from fashion to electronics, and services like education and real estate, all in one place📍</p>

                    </div> */}
        </div>

        <div className='policy-section'>
          <img className='brand-logo' src={DB_brandmark_logo1} />
          <h2>DigiBudding</h2>
          <h3>You don't want to continue with us on this journey</h3>

          <h2>Delete Your Account</h2>
          <div className='delete-account-btn-section'>
            <input value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} maxLength={10} minLength={10} type='number' placeholder='Enter Mobile Number' />
            <button onClick={onDelete}>Delete</button>
          </div>
        </div>

        <Footer />
      </section>
    </>
  )
}

export default DeleteAccount;