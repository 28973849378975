import React from 'react'
import DB_brandmark_logo1 from "../Images/DB_brandmark_logo1.svg"
import Navbar from './Navbar'
import Nav from './Nav'
import Footer from './Footer'

const TermsAndUse = () => {
  return (
    <>
      <section className='privacy-policy'>
        <div className='header'>
          <div>
            <h1>DIGIBUDDING</h1>
            <img className='logo' src={DB_brandmark_logo1}></img>
          </div>
          <Navbar />

        </div>

        <div className='small-device-nav'>
          <Nav />




          {/* <div className='small-device-content'>
                        <img src={DB_brandmark_logo1}></img>
                        <h1 className='logo-heading'>DigiBudding</b>

                        <p>Welcome to Digibudding, the ultimate e-commerce app connecting buyers and sellers in a whole new way. Our unique feature helps you find regional sellers and supports  businesses.</p>
                        <p>  Discover a diverse range of products, from fashion to electronics, and services like education and real estate, all in one place📍</p>

                    </div> */}
        </div>

        <div className='terms-section'>
          <h2>Terms and Conditions for DigiBudding</h2>
          <p>
            These Terms and Conditions ("Agreement") are entered into by and between the user ("User" or "You") and DigiBudding India Pvt. Ltd. ("Company," "We," "Us," or "Our"). This Agreement governs the use of the DigiBudding (the "App") and the services provided through the App.
          </p>

          <h2>1. Acceptance of Terms</h2>
          <p>

            By accessing or using the App, you agree to be bound by these Terms and Conditions. 
          </p>

          <h2>2. Use of the App</h2>
          
          <p>
            <b>a. Digital Catalogs and Banners: </b>The App enables business owners to create digital catalogs and banners. You agree to use these features in compliance with all applicable laws and regulations.
          </p>
          
          <p>
            <b>b. User Responsibilities: </b>You are responsible for maintaining the confidentiality of your account and restricting access to your account. You agree to accept responsibility for all activities that occur under your account.
          </p>

          <h2>3. Intellectual Property</h2>
          
          <p>
            <b>a. Ownership: </b>The App, including but not limited to its design, graphics, text, and other content, is the intellectual property of the Company. You may not use, reproduce, or distribute any content from the App without prior written permission from the Company.</p>
          
          <p><b>b. User Content: </b>By using the App, you grant the Company a non-exclusive, royalty-free, worldwide license to use, display, and reproduce any content you create using the App, solely for the purpose of providing and improving the App's services.</p>


          <h2>4. Payment and Billing</h2>
          
          <p><b>a. Subscription Fees: </b>Business owners using the App may be required to pay subscription fees. The fees, payment frequency, and billing methods will be specified on the App.</p>   
          
          <p><b>b. Cancellation:</b>Users may cancel their subscription at any time. No refunds will be provided for the unused portion of a subscription.</p>       
        
          <h2>Content</h2>
          
          <p><b>a. Data Collection: </b>The Company may collect and use personal information in accordance with its Privacy Policy. By using the App, you consent to the collection and use of your personal information as outlined in the Privacy Policy.</p>
          
          <p><b>b. Submission of Correct Information: </b>Business owners are required to submit accurate and truthful information when using the App, including but not limited to account registration details and business information.</p> 
          
          <p><b>c. Forgery: </b>Submission of forged or misleading documents is strictly prohibited. Violation of this policy may result in the termination of the account.</p>
          
          <p><b>d. Illegal Content: </b>Users should not submit, upload, or display any content that violates applicable laws or regulations.</p>
          
          <p><b>e. Explicit or Offensive Content: </b>The submission of explicit, offensive, or inappropriate images is strictly prohibited. This includes, but is not limited to, content that is sexually explicit, violent, discriminatory, or otherwise offensive.</p>
          
          <p><b>f. Public Platform: </b>As the App may be a public platform, users must adhere to community standards and guidelines. Content that is deemed inappropriate may lead to the termination of the account.</p>   
          
          <h2>6. Limitation of Liability</h2>
          <p>The Company is not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use of the App.</p>

          <h2>7. Termination</h2>
          <p>The Company reserves the right to terminate or suspend your access to the App at its discretion, without notice, for any reason, including but not limited to a violation of these Terms and Conditions.</p>

          <h2>8. Changes to Terms</h2>
          <p>The Company reserves the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on the App. Your continued use of the App after the posting of changes constitutes your acceptance of such changes.</p>

          <h2>9. Governing Law</h2>
          <p>This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or in connection with this Agreement shall be resolved through arbitration in accordance with the rules of the [Arbitration Association].</p>

          <p>By using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you have any questions or concerns, please contact at connect@digibudding.com.


          <br></br>
          <br></br><br></br><br></br>
          <h2> Refund Policy for DigiBudding </h2>


          <p>This Refund Policy outlines the terms and conditions under which refunds are provided for the use of DigiBudding (the "App") provided by DigiBudding India Pvt. Ltd. ("Company," "We," "Us," or "Our"). Please read this policy carefully before using the App.</p>
              <h2>1. Subscription Fees</h2>

          <p>a. Refund Criteria: Subscription fees are generally non-refundable. Refunds will only be considered under exceptional circumstances and at the sole discretion of the Company.</p>

          <p>b. Cancellation: Users may cancel their subscription at any time, but no refunds will be provided for the unused portion of a subscription.</p>


          <h2>2. Technical Issues</h2>

          <p>a. Refund for Service Disruption: In the event of a significant disruption to the App's services due to technical issues on our end, the Company may, at its discretion, provide a pro-rata refund for the affected period.</p>

          <p>b. Reporting Issues: Users experiencing technical issues should promptly report the problem to support@digibudding.com. The Company will make reasonable efforts to resolve reported issues promptly.</p>


          <h2>3. Unauthorized Access</h2>

          <p>a. Refunds for Unauthorized Access: If you believe there has been unauthorized access to your account, and you have incurred charges as a result, please contact support@digibudding.com immediately. The Company will investigate and, if necessary, provide a refund.</p>


          <h2>4. Change in Terms</h2>

          <p>a. Refunds in Case of Policy Changes: In the event of a significant change to this Refund Policy, users who are materially affected by the change may request a refund within 3-4 working days of the policy change.</p>


          <h2>5. How to Request a Refund</h2>

          <p>a. Contact Support: To request a refund, users must contact support@digibudding.com and provide details of the reason for the refund request.</p>

          <p>b. Evaluation: Refund requests will be evaluated on a case-by-case basis, and the Company will make reasonable efforts to respond to refund requests in a timely manner.</p>


          <h2>6. No Refunds for Violations</h2>

          <p>a. Policy Violations: Users who violate the Terms and Conditions may not be eligible for a refund.</p>


          <h2>7. Governing Law</h2>

          <p>This Refund Policy shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or in connection with this Refund Policy shall be resolved through arbitration in accordance with the rules of the [Arbitration Association].

          By using the App, you acknowledge that you have read, understood, and agree to be bound by this Refund Policy. If you have any questions or concerns, please contact support@digibudding.com.</p>





</p>
        </div>

        <Footer />
      </section>
    </>
  )
}

export default TermsAndUse