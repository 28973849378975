import React from 'react'
import polarBanner from "../Images/PolarBanner.svg"

const Banner = () => {
  return (
    <>
    <section className='banner'>
      <marquee scroll="behavior" direction="left">
      <img className='banner-img' src={polarBanner}/>
      </marquee>
    </section>
    </>
  )
}

export default Banner