import React from 'react'
import { IMG_CDN_URL } from '../constant/constantData'
import EcommercePriceCard from "../Images/EcommercePriceCard.svg"
import DigibuddingPriceCard from "../Images/DigibuddingPriceCard.svg"
import CustumerImg from "../Images/CustomerImg.png"

const CommunityPage = () => {
    return (
        <>
            <section className='communtiy-page'>
                <h1 className='community-heading'>We care About <span>You,</span> and  <span>The community</span></h1>

                <div className='community-section'>
                    <div className='community-card-section'>
                        <div className='ecommerce-card'>
                            <h3>Other E- Commerce Platforms</h3>
                            <img src={EcommercePriceCard} />
                        </div>
                        <div className='digibudding-card'>
                            <h3>DigiBudding</h3>
                            <img src={DigibuddingPriceCard} />
                        </div>

                    </div>

                </div>

                <img className='customer-img' src={CustumerImg} />

            </section>
        </>
    )
}

export default CommunityPage