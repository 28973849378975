
import './App.css';
import About from './components/About';
import Body from './components/Body';
import { createBrowserRouter, RouterProvider, Routes, Route,BrowserRouter,Switch,outlet } from 'react-router-dom'
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import Team from './components/Team';
import TermsAndUse from './components/TermsAndUse';
import DeleteAccount from './components/DeleteAccount';


function App() {

  
  return (
    <div className="App">
     
    
  
    <Body/>
    <outlet></outlet>
   
    
    </div>
  );
}


export const appRouter = createBrowserRouter([
  
   
    
      {
        path: "/",
        element: <Body/>
      },
      {
        path: "/about",
        element: <About/>
      },
      {
        path: "/contact",
        element: <Contact/>
      },
      {
        path: "/policy",
        element: <PrivacyPolicy/>
      },
      {
        path: "/terms",
        element: <TermsAndUse />
      },
      {
        path: "/deleteAccount",
        element: <DeleteAccount />
      },
      {
        path: "/team",
        element: <Team/>
      }
      
      
  
  
  
  


])

export default App;
