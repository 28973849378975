import React from 'react'
import { useParams} from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production'

const Navbar = () => {
    const currentUrl = window.location.pathname;
console.log('url',currentUrl)
    return (
        <>

            {/* <BrowserRouter> */}
                <div className='Navbar'>
                    <nav>
                        <ul >
                            <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}><li >Home</li> </Link>
                            <Link   smooth={true} to="#businessPage" style={{ display: `${currentUrl  == '/about' || currentUrl  == '/team' || currentUrl  == '/contact'|| currentUrl  ==  '/policy' ? "none":""}`, color: 'inherit', textDecoration: 'inherit' }}>  <li>For Business Owners</li> </Link>
                            <Link smooth={true} to="#customerPage" style={{ display: `${currentUrl  == '/about' || currentUrl  == '/team' || currentUrl  == '/contact'|| currentUrl  ==  '/policy' ? "none":""}`, color: 'inherit', textDecoration: 'inherit' }}><li>For Shoppers</li></Link>
                            <Link smooth={true} to="#missionPage" style={{ display: `${currentUrl  == '/about' || currentUrl  == '/team' || currentUrl  == '/contact'|| currentUrl  ==  '/policy' ? "none":""}`, color: 'inherit', textDecoration: 'inherit' }}><li>Mission</li></Link>
                            <Link smooth={true} to="#collaboratePage" style={{ display: `${currentUrl  == '/about' || currentUrl  == '/team' || currentUrl  == '/contact'|| currentUrl  ==  '/policy' ? "none":""}`, color: 'inherit', textDecoration: 'inherit' }}><li>Grow with us</li></Link>
                            <Link  to="/about" style={{ color: 'inherit', textDecoration: 'inherit' }}> <li>About Us</li></Link>
                            <Link smooth={true} to="/team" style={{ color: 'inherit', textDecoration: 'inherit' }}><li>The Team</li></Link>
                            <Link smooth={true} to="/contact" style={{ color: 'inherit', textDecoration: 'inherit' }}><li>Contact</li></Link>
                        </ul>
                    </nav>
                    <button className='signup'>SIGN UP</button>
                </div>
            {/* </BrowserRouter> */}


        </>
    )
}

export default Navbar