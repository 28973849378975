import React from 'react'
// import DB_brandmark_logo1 from "../Images/DB_brandmark_logo1.svg"
import { HashLink as Link } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production'
import { BiLogoFacebook} from "react-icons/bi";
import { AiOutlineInstagram,AiOutlineYoutube,AiFillLinkedin} from "react-icons/ai";
import { IMG_CDN_URL } from '../constant/constantData'


const Footer = () => {
  return (
    <>
      <section className='footer-page'>
        <div className='logo-section'>
          <img className='logo-img' src={`${IMG_CDN_URL}DB_brandmark_logo1.svg`} />
          <div className='sub-logo-section'>
            <h1>DigiBudding.com</h1>
            <h3>Discover, Connect, Shop.</h3>
          </div>

        </div>

        <div className='resources'>
          <h1>Resources</h1>
          <h3>Signup</h3>
          <h3><Link to="/deleteAccount" style={{ color: 'inherit', textDecoration: 'inherit' }}>Delete Account</Link></h3>
          <div>
          <a style={{cursor:"pointer"}} href="https://www.facebook.com/people/DigiBudding/100092352742706/" target="_blank" rel="noreferrer" ><BiLogoFacebook fill="blue" color='white' fontSize="30px" /></a>
          <a style={{cursor:"pointer"}} href="https://www.instagram.com/digibudding/" target="_blank" rel="noreferrer"><AiOutlineInstagram fill="#ff008f" color='white' fontSize="30px" padding="10px"/></a>
          <a style={{cursor:"pointer"}} href=" https://www.youtube.com/channel/UC11TswGuiVhdUD8nlMAv8VA" target="_blank"><AiOutlineYoutube fill='#f80404' fontSize="30px"/></a>
          {/* <AiFillLinkedin fill='#0096e2' fontSize="30px"/> */}
          </div>
        </div>

        <div className='legal'>
          <h1>Legal</h1>
          <h3><Link to="/terms" style={{ color: 'inherit', textDecoration: 'inherit' }}> Terms of Use</Link></h3>
          <h3>Your Rights</h3>
          <h3>Consent</h3>     
          <h3><Link to="/policy" style={{ color: 'inherit', textDecoration: 'inherit' }}> Privacy Policy</Link></h3>
        </div>


      </section>
      <div className='legal_footer'>

          <h5 >Copyright 2024 DigiBudding India Private Limited</h5>     

        </div>
    </>
  )
}

export default Footer;