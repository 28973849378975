import React, { useState } from "react";
import Button from "./Button";
import menuItems from "./MenuItems";
import { BrowserRouter } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link/dist/react-router-hash-link.cjs.production'
import "./Navbar.css";
import DB_brandmark_logo1 from "../Images/DB_brandmark_logo1.svg"
import { GiHamburgerMenu} from "react-icons/gi";
import {AiOutlineClose} from "react-icons/ai";


const Nav = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const currentUrl = window.location.pathname;

  return (
    
    <nav className="navbar">
      
      <h1 className="navbar-logo">
        DIGIBUDDING
        {/* <i className="fab fa-react"></i> */}
      </h1>
      <img src={DB_brandmark_logo1}></img>
      
      <div className="menu-icon" onClick={handleClick}>
        {active ?<AiOutlineClose color="white"/> : <GiHamburgerMenu color="white"/>}
      </div>
      {/* <BrowserRouter> */}
      <ul className={active ? "nav-menu active" : "nav-menu"}>
        {menuItems.map((item, index) => {
          return (
            <li style={{outline:"none !important", display: `${item.idName === "#businessPage" || item.idName === "#customerPage" || item.idName ==="#missionPage" || item.idName ==="#collaboratePage" ?  currentUrl  == '/about' || currentUrl  == '/team' || currentUrl  == '/contact' ||  currentUrl  ==  '/policy' ? "none":"":""}`}} key={index}>
              <a>
              <Link   smooth={true}  to={item.idName} className={item.cName}>
                {item.title}
              </Link>
              </a>
            </li>
          );
        })}
      </ul>
      {/* </BrowserRouter> */}
      {/* < Button style={{display:"none"}}>SIGN UP</Button> */}
    </nav>
  );
};

export default Nav;
