import React,{useState,useEffect} from 'react'
import LeftIcon from "../Images/Left.svg"
import RigthIcon from "../Images/Right.svg"
// import Inverted from "../Images/Inverted.svg"
import Rating from "../Images/Rating.svg"
import { People } from '../constant/Peaple'
import { IMG_CDN_URL } from '../constant/constantData'

const Testimonial = () => {
  const [users, setUsers] = useState(People);
  const [index, setIndex] = useState(0);


  useEffect(() => {
    const lastIndex = users.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, users]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 4500);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <>

      <section className='about-section'>
        <h1>What Are People Saying <br /> About Us?</h1>

      </section>
      <section className='testimonial-page'>


      
       <img className='btn-icon' src={LeftIcon} onClick={()=>setIndex(index-1)} />
      
        <div className='testimonial-carousel'>
          <img className='Inverted-img' src={`${IMG_CDN_URL}Inverted.svg`} />
          {
            users.map((person,personIndex)=>{
              let position = "nextSlide";
              if (personIndex === index) {
                position = "activeSlide";
              }
              if (
                personIndex === index - 1 ||
                (index === 0 && personIndex === users.length - 1)
              ) {
                position = "lastSlide";
              }


              return(
                
                <article className={position} key={person.id}>
                <h1 className='testimonial-name'>{person.name}</h1>
                <h3 className='company-name'>{person.company}</h3>
                <img src={ Rating} />
                <p>{person.feedback}</p>
                </article>
                
              )
            })
          }
         
         <div className='flex-dot'>
          {users.map((user,id)=>{
            return(
              <><div className='slider-dot' style={{background: user.id-1==index?"blue":"gray"}} key={id}></div></>
            )
          })}
          </div> 
          {/* <h1 className='testimonial-name'>Shreya Mehta</h1>
          <h3 className='company-name'>Shreya’s Handloooms</h3>
          <img src={Rating} />
          <p>"Digibudding's analytics feature is a game-changer! It <br /> provides me with valuable insights into my business <br /> performance, helping me make informed decisions and boost <br /> my sales. It's like having a secret weapon for success!" </p> */}
        </div>
       
        <img className='btn-icon' src={RigthIcon}   onClick={()=>setIndex(index+1)}/>

      </section>
    </>
  )
}

export default Testimonial