import React from 'react'
// import NewsletterImg from "../Images/Newsletter.svg"
import { IMG_CDN_URL } from '../constant/constantData'

const NewsLetter = () => {
  return (
    <>
      <section className='newsletter-page'>

        <div style={{ backgroundImage: `url(${IMG_CDN_URL}Newsletter.svg)`, width: "80%", height: "300px", margin: "auto", backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} className='newsletter-background'>

          <h1>Join The DigiBudding <br /> Family Today!</h1>
          <div className='newsletter-btn-section'>
            <input placeholder='Your Email Address' />
            <button>sign up</button>
          </div>
        </div>

      </section>
    </>
  )
}

export default NewsLetter