const menuItems = [
  {
    title: "Home",
    url: "#",
    cName: "nav-links",
    idName: "/"
  },
  {
    title: "For Business",
    url: "#",
    cName: "nav-links",
    idName:"#businessPage"
  },
  {
    title: "For Shoppers",
    url: "#",
    cName: "nav-links",
    idName:"#customerPage"
  },
  {
    title: "Mission",
    url: "#",
    cName: "nav-links",
    idName:"#missionPage" 
  },
  {
    title: "Grow with us",
    url: "#",
    cName: "nav-links",
    idName:"#collaboratePage"
  },
  {
    title: "About Us",
    url: "#",
    cName: "nav-links",
    idName:"/about"
  },
  {
    title: "Our Team",
    url: "#",
    cName: "nav-links",
    idName:"/team"
  },
  {
    title: "Contact",
    url: "#",
    cName: "nav-links",
    idName:"/contact"
  },
  {
    title: "Sign Up",
    url: "#",
    cName: "nav-links-mobile",
   
  }
];

export default menuItems;
