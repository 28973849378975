import React from 'react'
import Header from './Header'
import Welcome from './Welcome'
import Shop from './Shop'
import BusinessOwner from './BusinessOwner'
import MissionPage from './MissionPage'
import CollaborationPage from './CollaborationPage'
import About from './About'
import Testimonial from './Testimonial'
import NewsLetter from './NewsLetter'
import Footer from './Footer'
import Nav from './Nav'
import Banner from './Banner'


const Body = () => {
  return (
    <div>

      {/* <Nav/> */}
    
      <Header />
      <Welcome />
      <Banner/>
    <div id="customerPage"><Shop /></div>
        <div id="businessPage"><BusinessOwner /></div>
     <div id='missionPage'> <MissionPage /></div>
      <div id="collaboratePage"><CollaborationPage /></div>
     
       <Testimonial />
      <div id='contact'>  <NewsLetter /> </div> 
       <Footer />
    </div>
  )
}

export default Body