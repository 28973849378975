import React from 'react'
import CommunityPage from './CommunityPage'
import { IMG_CDN_URL } from '../constant/constantData'

const BusinessOwner = () => {
    return (
        <>
            <div className='owner-page-section'>

                <h1 className='owner-page-heading'>What’s in it for business owners?<br />
                    One App, <span>Multiple Solutions.</span></h1>


                <div className='owner-para-section'>
                    <img className='growth-img' src={`${IMG_CDN_URL}Growth.svg`} />

                    <div>
                        <h1 className='owner-par-heading'>A Whole New Way To Connect</h1>
                        <p className='owner-para-one'>We aim to create a user-friendly e-commerce <br /> platform connecting buyers and  sellers, combining <br /> online shopping convenience with regional business <br /> discovery.</p>

                        <h1 className='owner-par-heading'>Fee-Free Seller Onboarding</h1>
                        <p className='owner-para-two'>We promote a thriving seller community with a fee-free <br /> onboarding process, allowing  businesses of all <br /> sizes to showcase their products without financial <br /> constraints.</p>

                        <h1 className='owner-par-heading'>Empowering  Businesses</h1>
                        <p>DigiBudding supports local merchants, by helping <br /> them enhance their brand identity and increasing <br /> their footfall by helping customers find nearby <br /> products and services  easily.</p>
                    </div>

                </div>

            </div>

            <CommunityPage />
        </>
    )
}

export default BusinessOwner