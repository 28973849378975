import React from 'react'
import CollaborationImg from "../Images/colaborate1Img.svg";
import { IMG_CDN_URL } from '../constant/constantData'

const CollaborationPage = () => {
  return (
   <>
   <section className='collaboration-page'>
    <div style={{backgroundImage: `url(${CollaborationImg})`, width:"100%", height:"900px", backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat', backgroundPosition:"bottom"}} className='collaboration-background'>
       
        {/* <div className='collaboration-heading'>
          <h1>Collaborate With Us</h1>
        </div> */}

       

    </div>
    <div className='collaboration-card'>
            <p>DigiBudding: Your Partner in Growth! Explore limitless possibilities <br/> through collaboration with us and watch your business bloom.</p>
        </div>
   </section>
   </>
  )
}

export default CollaborationPage