export const People = [
    {
        id: 1,
        name: 'Shreya Mehta',
        company: 'Shreya’s Handloooms',
        feedback:"Digibudding's analytics feature is a game-changer! It provides me with valuable insights into my business performance, helping me make informed decisions and boost my sales. It's like having a secret weapon for success!"
    },
    {
        id: 2,
        name: 'Dhiraj Agarwal',
        company: 'Shopper',
        feedback:"This app has become my go-to for discovering nearby treasures. Its user-friendly interface makes it so easy to explore the hidden gems in my neighborhood. I've stumbled upon unique products and services that I might never have found otherwise."
    },
    {
        id: 3,
        name: 'Sushil Gupta',
        company: 'Shopper',
        feedback:"What truly sets Digibudding apart is its fee-free seller onboarding, which makes it so welcoming for all types of businesses. It's a win-win for both buyers like me and the  sellers."
    },
    {
        id: 4,
        name: 'Sumit Negi',
        company: 'Business Owner',
        feedback:"As a business owner, Digibudding has been a game-changer for my  establishment! 🌟 This incredible app has opened up a whole new world of opportunities for us. With Digibudding's user-friendly platform, we've been able to connect with customers right in our neighborhood seamlessly."
    }
] 