import React from 'react'
import Trednding_Mobile_View from "../Images/Home.png"
import Play_Mobile_View from "../Images/play.png"
// import Sparkle from "../Images/Sparkle.svg"
import { IMG_CDN_URL } from '../constant/constantData'


const Welcome = () => {
    return (
        <>
            <section>
                <div className='main-view'>
                <img className='top-leftSparkle-img' src={`${IMG_CDN_URL}Sparkle.svg`}/>
                    <img className='left-mobile-view' src={Trednding_Mobile_View} />
                  
                    <div className='main-content'>
                        <h1>Hey there, and Welcome to DigiBudding!</h1>
                        <p>Welcome To DigiBudding, The Ultimate <br className='welcome-page-br'/> E-commerce App Connecting Buyers <br className='welcome-page-br'/> And Sellers In A Whole New Way.</p>
                        <p> Our Unique Feature Helps You Find <br className='welcome-page-br'/> Regional Sellers And Supports Our <br className='welcome-page-br'/> Community.</p>

                    </div>
                    <img className='bottom-leftSparkle-img' src={`${IMG_CDN_URL}Sparkle.svg`}/>

                    <img className='right-mobile-view' src={Play_Mobile_View} />

                    <img className='bottom-rightSparkle-img' src={`${IMG_CDN_URL}Sparkle.svg`}/>

                    

                </div>

            </section>
        </>
    )
}

export default Welcome