import React from 'react'
import Navbar from './Navbar'
// import DB_brandmark_logo1 from "../Images/DB_brandmark_logo1.svg"
// import Online_Shopping from "../Images/Online_Shopping.svg"
import Nav from './Nav'
import { IMG_CDN_URL } from '../constant/constantData'
import groupedImg from "../Images/groupedImg.svg"
import {FaGooglePlay} from "react-icons/fa"
import {BsApple} from "react-icons/bs"

const Header = () => {
    return (
        <>
            <section className='parent-header'>
                <div className='header'>
                    <div>
                        <h1>DIGIBUDDING</h1>
                        <img className='logo' src={`${IMG_CDN_URL}DB_brandmark_logo1.svg`}></img>
                    </div>
                    <Navbar />

                </div>

                <div className='small-device-nav'>
                    <Nav />


                  

                    {/* <div className='small-device-content'>
                        <img src={DB_brandmark_logo1}></img>
                        <h1 className='logo-heading'>DigiBudding</h1>

                        <p>Welcome to Digibudding, the ultimate e-commerce app connecting buyers and sellers in a whole new way. Our unique feature helps you find regional sellers and supports  businesses.</p>
                        <p>  Discover a diverse range of products, from fashion to electronics, and services like education and real estate, all in one place📍</p>

                    </div> */}
                </div>


                <div className='header-content'>
                    <div className='sub-header'>
                        <img className="sub-header-logo" src={`${IMG_CDN_URL}DB_brandmark_logo1.svg`} />
                        <h1 className='logo-heading'>DigiBudding.com</h1>
                        <br></br>

                        <div>
                            <p>Discover, Connect, Shop</p>
                            <input placeholder='Everything you need,All in one place' />
                         
                          <h2 >Available On</h2>
                            <button>{<FaGooglePlay style={{fontSize:"22px"}}/>}</button>
                            <button className='btn-2'>{<BsApple style={{fontSize:"22px"}} />}</button>
                         
                        </div>

                    </div>

                    <img className='gorupedImg' src={groupedImg}></img>
                    <img className='shoppingbox' src={`${IMG_CDN_URL}Online_Shopping.svg`}></img>


                </div>


            </section>
        </>
    )
}

export default Header