import React from 'react'
import Nav from './Nav'
import { IMG_CDN_URL } from '../constant/constantData'
import Navbar from './Navbar'
import teamCard from "../Images/TeamCard.svg"
import Footer from './Footer'

const Team = () => {
    return (
        <section className='team-body'>
            <div className='header'>
                <div>
                    <h1>DIGIBUDDING</h1>
                    <img className='logo' src={`${IMG_CDN_URL}DB_brandmark_logo1.svg`}></img>
                </div>
                <Navbar />

            </div>

            <div className='small-device-nav'>
                <Nav />
            </div>

            <div className='team-flex'>
                <div className='team-text-card'>
                    <h1 className='team-heading'>Meet The DigiBudding Dream Team!🚀</h1>
                    <p className='team-para'>Meet the DigiBudding crew, the friendly faces behind the digital curtain, pulling the strings to bring  businesses and shoppers together. With technology and passion, we craft a seamless world where  businesses shine, and customers find treasures at every click.</p>
                </div>
                <img className='team-img-card' src={teamCard}/>
            </div>
            <Footer/>

        </section>
    )
}

export default Team