import React from 'react'
import Testimoniallmage from "../Images/misionImg.jpg"
import blueSpring from "../Images/blueSpring.svg"
import boldSpring from "../Images/boldSpring.png"
import { IMG_CDN_URL } from '../constant/constantData'

const MissionPage = () => {
  return (

    <>
      <section className='mission-page'>
        <h2 className='mission-heading'>Our Mission</h2>
        <p className='mission-sub-para'>At DigiBudding, our mission is to bring the best of both worlds- online <br className="mission-para-br" /> convenience and local treasures, right to your fingertips! 📲</p>

        <img className='blueSpring' src={blueSpring}/>
        <img className='boldSpring' src={boldSpring}/>
        <div className='testimonial-card'>
         
          <div className='card-section'>
            <img src={Testimoniallmage} />
            <div>
              <p>We aim to empower buyers and sellers by <br className='card-br' /> enabling customers to find nearby sellers <br className='card-br'/> effortlessly. Our app aims to boost footfall for <br className='card-br' /> local merchants, supporting their growth and <br className='card-br'/> visibility while offering a diverse range of <br className='card-br'/> products and services, from fashion and <br className='card-br'/> beauty to electronics, groceries, and more. </p>
              <h5>Where convenience meets community, and every <br /> purchase makes a difference.</h5>
            </div>
          </div>




        </div>


        {/* -----------horizontal card------------------------- */}

        {/* <div className='card-container'>
          <div className='card-1'>
            <div className='card-image-1'>
              <img src={Testimoniallmage}></img>
            </div>
            <div className='card-text-1'>
              <p>We aim to empower buyers and sellers by  enabling customers to find nearby sellers  effortlessly. Our app aims to boost footfall for  local merchants, supporting their growth and visibility while offering a diverse range of  products and services, from fashion and  beauty to electronics, groceries, and more. </p>
              <h5>Where convenience meets community, and every  purchase makes a difference.</h5>

            </div>
          </div>
        </div> */}

      </section>
    </>
  )
}

export default MissionPage